$--theme-primary: --theme-primary;
$--theme-primary-hover: --theme-primary-hover;
$--theme-light: --theme-light;
$--theme-dark: --theme-dark;
$--theme-darker: --theme-darker;
$--theme-text-contrast: --theme-text-contrast;
$--theme-form-required: --theme-form-required;
/* Used for things like the header */
$--theme-header: --theme-header;
$--theme-header-border: --theme-header-border;
$--theme-header-height: --theme-header-height;
$--theme-btn-accent: --theme-btn-accent;
$--theme-btn-accent-hover: --theme-btn-accent-hover;
$--theme-bg-card: --theme-bg-card;
$--theme-bg-card-footer: --theme-bg-card-footer;
$--theme-affix-page-seasonal-color: --theme-affix-page-seasonal-color;
$--theme-affix-page-seasonal-background-color: --theme-affix-page-seasonal-background-color;
$--theme-affix-page-current-week-border-color: --theme-affix-page-current-week-border-color;

// Superhero
$theme-map-superhero: (
        $--theme-primary: #df691a,
        $--theme-primary-hover: #b15315,
        $--theme-light: #abb6c2,
        $--theme-dark: #4e5d6c,
        $--theme-darker: #2B3E50,
        $--theme-text-contrast: #ebebeb,
        $--theme-header-height: 114px,
        $--theme-form-required: var($--theme-primary),
        $--theme-header: var($--theme-dark),
        $--theme-header-border: unset,
        $--theme-btn-accent: var($--theme-primary),
        $--theme-btn-accent-hover: var($--theme-primary-hover),
        $--theme-bg-card: #4e5d6c,
        $--theme-bg-card-footer: rgba(255, 255, 255, 0.075),
        $--theme-affix-page-seasonal-color: #df691a,
        $--theme-affix-page-seasonal-background-color: #df691abb,
        $--theme-affix-page-current-week-border-color: rgb(235, 235, 235)
);

// Darkly
$theme-map-darkly: (
        $--theme-primary: #375a7f,
        $--theme-primary-hover: #4e7fb3,
        $--theme-light: #adb5bd,
        $--theme-dark: #303030,
        $--theme-darker: #202020,
        $--theme-text-contrast: #ebebeb,
        $--theme-header-height: 114px,
        $--theme-form-required: #c40000,
        $--theme-header: var($--theme-dark),
        $--theme-header-border: unset,
  /* The same as success button */
        $--theme-btn-accent: #00bc8c,
        $--theme-btn-accent-hover: #007053,
        $--theme-bg-card: #303030,
        $--theme-bg-card-footer: #444,
        $--theme-affix-page-seasonal-color: #00bc8c,
        $--theme-affix-page-seasonal-background-color: #00bc8cbb,
        $--theme-affix-page-current-week-border-color: rgb(235, 235, 235)
);

// Lux
$theme-map-lux: (
        $--theme-primary: #1a1a1a,
        $--theme-primary-hover: #1a1a1a,
        $--theme-light: #fff,
        $--theme-dark: #ebebeb,
        $--theme-darker: #ebebeb,
        $--theme-text-contrast: #1a1a1a,
        $--theme-header-height: 114px,
        $--theme-form-required: #c40000,
        $--theme-header: var($--theme-light),
        $--theme-header-border: 1px solid #1a1a1a,
  /* The same as success button */
        $--theme-btn-accent: #1a1a1a,
        $--theme-btn-accent-hover: black,
        $--theme-bg-card: #fff,
        $--theme-bg-card-footer: rgba(0, 0, 0, 0.03),
        $--theme-affix-page-seasonal-color: #df691a,
        $--theme-affix-page-seasonal-background-color: #df691abb,
        $--theme-affix-page-current-week-border-color: var($--theme-primary)
);
